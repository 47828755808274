// DashboardPage component
import React, { useEffect, useState, useCallback } from 'react';
import { Paper, Typography, Grid } from '@mui/material';
import { getLicenses } from '../services/licenseService';
import { getVixScore } from '../services/vixService';
import VixCard from '../components/VixCard';
import Layout from '../components/Layout';
import EconomicEventTimeline from '../components/EconomicEventTimeline';
import RiskStatusCard from '../components/RiskStatusCard';
import RiskAvailabilityCard from '../components/RiskAvailabilityCard';
import { RiskLevel } from '../helpers/RiskLevel';

const DashboardPage = () => {
  const [vixData, setVixData] = useState({
    vix_score: 0,
    prev_close: 0,
    open_price: 0,
    "52_week_high": 0,
    "52_week_low": 0,
    percentage_change: 0,
  });
  const [licenseCount, setLicenseCount] = useState(0);
  const [selectedDate] = useState(() => {
    const now = new Date();
    const localDate = new Date(now.getTime() - now.getTimezoneOffset() * 60000);
    return localDate.toISOString().split('T')[0];
  });

  const fetchLicenses = useCallback(async () => {
    try {
      const data = await getLicenses();
      setLicenseCount(data.length);
    } catch (error) {
      console.error('Error fetching licenses:', error);
    }
  }, []);

  const fetchVixData = useCallback(async () => {
    try {
      const data = await getVixScore();
      const percentage_change = ((data.vix_score - data.prev_close) / data.prev_close) * 100;
      setVixData({ ...data, percentage_change });
    } catch (error) {
      console.error('Error fetching VIX score:', error);
    }
  }, []);

  useEffect(() => {
    fetchLicenses();
    fetchVixData();

    const vixInterval = setInterval(fetchVixData, 10 * 60 * 1000); // Fetch every 10 mins

    return () => clearInterval(vixInterval);
  }, [fetchLicenses, fetchVixData]);

  return (
    <Layout>
      <Grid container spacing={3} mb={4}>
        {/* Economic Event Timeline */}
        <Grid item xs={12} md={6}>
          <EconomicEventTimeline date={selectedDate} />
        </Grid>

        {/* VixCard and other components */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <VixCard vixData={vixData} />
            </Grid>

            <Grid item xs={12}>
              <RiskAvailabilityCard showUpcomingOnly="false" />
            </Grid>

            {/* Risk Status Cards - Display 2 per row */}
            <Grid item xs={12} md={6}>
              <RiskStatusCard riskLevel={RiskLevel.High} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RiskStatusCard riskLevel={RiskLevel.Medium} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RiskStatusCard riskLevel={RiskLevel.Low} />
            </Grid>
            <Grid item xs={12} md={6}>
              <RiskStatusCard riskLevel={RiskLevel.None} />
            </Grid>

            <Grid item xs={12}>
              <RiskAvailabilityCard />
            </Grid>

            {/* Total Licenses Card */}
            <Grid item xs={12} md={6}>
              <Paper
                sx={{
                  p: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: 'purple',
                  color: 'white',
                }}
              >
                <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold' }}>
                  {licenseCount}
                </Typography>
                <Typography variant='body1'>Total Licenses</Typography>
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default DashboardPage;
