import axiosInstance from './authService';

export const checkRiskAvailability = async (riskParams) => {
  try {
    const response = await axiosInstance.post('/IsRiskAvailable', riskParams);
    return response.data;
  } catch (error) {
    console.error('Error checking risk availability:', error);
    throw error;
  }
};

export const getRiskStatus = async (riskParams) => {
  try {
    const response = await axiosInstance.post('/GetRiskStatus', riskParams);
    return response.data;
  } catch (error) {
    console.error('Error checking risk availability:', error);
    throw error;
  }
};
